import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledWidgetsHero = styled.section`
	color: ${CSSVars.gray900};
	text-align: center;
	overflow: hidden;
	padding: 50px 20px 0;

	@media screen and (min-width: ${CSSVars.breakpointLG}) {
		padding: 100px 40px 0;
	}

	.hero-wrapper {
		position: relative;

		span {
			font-size: ${CSSVars.fontSize2XL};
			margin-bottom: 20px;
			display: block;
			color: ${CSSVars.primary500};
			font-weight: 600;
		}

		h1 {
			position: relative;
			z-index: 10;
			margin: 0 auto;
			font-size: ${CSSVars.fontSize2XL};
			font-weight: 600;
			max-width: 750px;
			margin-bottom: 20px;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSize4XL};
			}
		}

		h2 {
			position: relative;
			z-index: 10;
			margin: 0 auto 50px;
			font-size: ${CSSVars.fontSizeLG};
			max-width: 670px;
			font-weight: 300;

			@media screen and (min-width: ${CSSVars.breakpointMD}) {
				font-size: ${CSSVars.fontSizeXL};
			}
		}
	}
`;
