import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const StyledDiv = styled.div`
	.posts {
		max-width: 1420px;
		margin: 50px auto 0;

		@media screen and (min-width: ${CSSVars.breakpointMD}) {
			margin: 100px auto 0;
		}

		a {
			background-color: ${CSSVars.gray100};
			position: relative;
			border: 1px solid #eeeeee;
			border-radius: 10px;
		}

		.post {
			border-radius: 10px;
			overflow: hidden;

			&:hover {
				.details {
					h3 {
						color: ${CSSVars.primary500};
					}
					.read-more {
						color: ${CSSVars.primary300};
					}
				}
			}

			&.first-post {
				margin-bottom: 100px;
				display: flex;
				align-items: center;

				flex-direction: column;
				gap: 10px;

				@media screen and (min-width: ${CSSVars.breakpointLG}) {
					flex-direction: row;
					gap: 50px;
				}

				.details {
					background-color: transparent;

					h3 {
						font-size: ${CSSVars.fontSize2XL};

						@media screen and (min-width: ${CSSVars.breakpointXL}) {
							font-size: ${CSSVars.fontSize4XL};
						}
					}

					.author {
						font-size: ${CSSVars.fontSizeXS};

						@media screen and (min-width: ${CSSVars.breakpointLG}) {
							font-size: ${CSSVars.fontSizeSM};
						}
					}

					.desc {
						font-size: ${CSSVars.fontSizeMD};
						-webkit-line-clamp: 5; /* number of lines to show */
						line-clamp: 5;
						margin-bottom: 20px;

						@media screen and (min-width: ${CSSVars.breakpointLG}) {
							font-size: ${CSSVars.fontSizeLG};
						}
					}

					.read-more {
						font-size: ${CSSVars.fontSizeMD};
						position: static;

						@media screen and (min-width: ${CSSVars.breakpointXL}) {
							font-size: ${CSSVars.fontSizeLG};
						}
					}
				}

				img {
					border-radius: 10px;
					max-width: 600px;
					width: 100%;
					height: 100%;
				}
			}

			img {
				object-fit: cover;
				height: 200px;
				width: 100%;
				border-radius: 10px 10px 0 0;
			}

			.details {
				padding: 20px 20px 40px;
				color: ${CSSVars.gray900};

				h3 {
					transition: 0.2s;
					font-size: ${CSSVars.fontSizeLG};
					font-weight: 600;
				}

				.author {
					font-size: ${CSSVars.fontSizeXS};
					color: ${CSSVars.gray400};
					font-weight: 300;
					margin: 5px 0 10px;
				}

				.desc {
					font-size: ${CSSVars.fontSizeSM};
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 4; /* number of lines to show */
					line-clamp: 4;
					-webkit-box-orient: vertical;
					margin-bottom: 10px;
				}

				.read-more {
					color: ${CSSVars.primary500};
					font-size: ${CSSVars.fontSizeSM};
					font-weight: 600;
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 10px;
					margin-left: auto;
					transition: 0.2s;
					width: fit-content;
					border: none;
					background-color: transparent;
					cursor: pointer;

					position: absolute;
					bottom: 20px;
					right: 20px;

					&:hover {
						color: ${CSSVars.primary300};
					}
				}
			}
		}

		.posts-container {
			display: grid;
			grid-gap: 50px;
			grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		}

		.no-items {
			display: flex;
			align-items: center;
			flex-direction: column;
			gap: 20px;
			margin: 0 auto 100px;
			text-align: center;

			button {
				font-size: 14px;
			}
		}
	}
`;
