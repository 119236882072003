import React, { useState } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { ReactDimmer } from 'react-dimmer';
import { StyledDiv } from "./newsLetterForm.styles";
import { toast } from 'react-toastify';

export const NewsLetterForm = () => {
    const url = 'https://commoninja.us11.list-manage.com/subscribe/post?u=4076742add55bb43380b94b22&amp;id=1cc0fef740';
    const [email, setEmail] = useState<string>('');
    const [isShown, setIsShown] = useState<boolean>(false);

    function notify(status: string) {
        setEmail('');
        if (!email) {
            toast.info('Email is required.');
            return;
        }
        if (status === 'success') {
            toast.success('Great Success 🎉');
            setIsShown(false);
            setEmail('');
            return;
        }
        setEmail('');
        toast.error(`Oops, something went wrong, please try again.`);
    }

    return (
        <>
            <StyledDiv >
                <div className={`form-wrapper ${isShown ? 'shown' : ''}`}>
                    <h4>Join Our Newsletter!</h4>
                    <p>Keep up to date with our latest blog posts, new widgets and features, and the Common Ninja Developer Platform.</p>
                    <MailchimpSubscribe url={url} render={({ status, subscribe, message }) => (
                        <form className="input-wrapper" onSubmit={(e) => { e.preventDefault(); subscribe({ EMAIL: email }); notify(status as string); }}>
                            <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Your email' type="email" />
                            <button>Subscribe!</button>
                        </form>
                    )} />
                </div>
                <div className="newsletter-btn" onClick={() => { setIsShown(!isShown); }}>
                    Subscribe
                </div>
            </StyledDiv >
            <ReactDimmer blur={2} opacity={0.4} isOpen={isShown} exitDimmer={() => { setIsShown(false); setEmail(''); }} />
            {/* <ToastContainer position='top-center' autoClose={2000} /> */}
        </>
    );
};