import React, { ReactNode } from 'react';
import cn from 'classnames';
import {
  AddButton,
  PrimaryButton,
  SecondaryButton,
  TransparentButton,
} from './button.styles';

interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  variant?: 'primary' | 'secondary' | 'add' | 'transparent';
  loading?: boolean;
  loadingComp?: ReactNode;
}

export const Button = ({
  children,
  variant = 'primary',
  loading = false,
  loadingComp = 'Loading...',
  ...props
}: IButtonProps) => {
  const disabled = loading || props.disabled;
  const className = cn({
    loading,
  }, props.className);
  const commonProps = {
    disabled,
    className,
  };

  function renderContent() {
    if (loading) {
      return loadingComp;
    }
    return children;
  }

  function renderButton() {
    switch (variant) {
      case 'secondary':
        return (
          <SecondaryButton {...props} {...commonProps}>
            {renderContent()}
          </SecondaryButton>
        );
      case 'add':
        return (
          <AddButton {...props} {...commonProps}>
            {renderContent()}
          </AddButton>
        );
      case 'transparent':
        return (
          <TransparentButton {...props} {...commonProps}>
            {renderContent()}
          </TransparentButton>
        );
      default:
        return (
          <PrimaryButton {...props} {...commonProps}>
            {renderContent()}
          </PrimaryButton>
        );
    }
  }

  return renderButton();
};
