import cn from 'classnames';
import { StyledUl } from './pagination.styles';
import { usePagination } from '../../hooks/usePagination';

interface IPaginationProps {
    onPageChange: (page: number) => void;
    totalCount: number,
    siblingCount?: number,
    currentPage: number,
    pageSize: number,
    className?: string;
}

export const Pagination = ({
    onPageChange,
    totalCount = 1,
    siblingCount = 0,
    currentPage = 1,
    pageSize = 1,
    className = ''
}: IPaginationProps) => {

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    // if (currentPage === 0 || (paginationRange?.length || 0) < 2) {
    //     return null;
    // }

    function onNext() {
        onPageChange(currentPage + 1);
    }

    function onPrevious() {
        onPageChange(currentPage - 1);
    }

    const lastPage = (paginationRange || [])[(paginationRange?.length || 0) - 1] || 1;

    return (
        <StyledUl
            className={cn('pagination-container', { [className]: className })}
        >
            <li
                className={cn('pagination-item', {
                    disabled: currentPage === 1
                })}
                onClick={onPrevious}
            >
                <div className="arrow left" />
            </li>
            {(paginationRange || []).map(pageNumber => {
                if (pageNumber === '...') {
                    return <li key={'...'} className="pagination-item dots">&#8230;</li>;
                }

                return (
                    <li
                        key={pageNumber}
                        className={cn('pagination-item', {
                            selected: pageNumber === currentPage
                        })}
                        onClick={() => onPageChange(Number(pageNumber))}
                    >
                        {pageNumber}
                    </li>
                );
            })}
            <li
                className={cn('pagination-item', {
                    disabled: currentPage === lastPage
                })}
                onClick={onNext}
            >
                <div className="arrow right" />
            </li>
        </StyledUl>
    );
};

