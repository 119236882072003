import styled from 'styled-components';
import { CSSVars } from '../../styles/styles.config';

export const PrimaryButton = styled.button`
  cursor: pointer;
  background-color: ${CSSVars.primary500};
  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 15px;
  transition: 0.2s;
  font-weight: 500;
  font-size: 1rem;
  font-family: ${CSSVars.primaryFont};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  a {
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  &:hover {
    background-color: ${CSSVars.primary700};
    color: white;

    a {
      color: inherit;
    }
  }

  &:active {
    background-color: ${CSSVars.primary300};
    color: white;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: transparent;
  border: 1px solid ${CSSVars.primary500};
  color: ${CSSVars.primary500};

  &:hover {
    background-color: ${CSSVars.primary500};
    color: white;
  }

  &:active {
    background-color: ${CSSVars.primary300};
    border-color: ${CSSVars.primary300};
    color: white;
  }
`;

export const AddButton = styled(PrimaryButton)`
  background-color: transparent;
  border: 1px dashed ${CSSVars.gray500};
  color: ${CSSVars.gray500};

  &:hover {
    background-color: transparent;
    border-color: ${CSSVars.gray300};
    color: ${CSSVars.gray300};
  }

  &:active {
    background-color: transparent;
    border-color: ${CSSVars.gray100};
    color: ${CSSVars.gray100};
  }
`;

export const TransparentButton = styled(PrimaryButton)`
  background-color: transparent;
  border: none;
  color: ${CSSVars.gray500};

  &:hover {
    background-color: transparent;
    color: ${CSSVars.gray300};
  }

  &:active {
    background-color: transparent;
    border-color: ${CSSVars.gray100};
    color: ${CSSVars.gray100};
  }
`;
